import { useCallback } from "react"

import {
  ClientTrackingEventName,
  FiltersModuleFieldId,
  IAdvisor,
} from "../../types"
import { DEFAULT_SORTING_MODE } from "../../ui-lib/Experts/Filters/consts"
import { mapExpertsToClientTrackingTrack } from "../../ui-lib/Experts/utils"
import { useRuntimeConfig } from "../runtimeConfig"
import useTracking from "./useTracking"

interface ITrackProps {
  eventName: ClientTrackingEventName
  content: IAdvisor[]
  customClientTrackingProperties: Record<string, unknown> | undefined
  filter?: Partial<Record<FiltersModuleFieldId, unknown>>
  search?: string
  sorting?: string
}

interface IExpertsListingTrackingHook {
  trackEvent: (props: ITrackProps) => void
}

const useExpertsListingTracking = (): IExpertsListingTrackingHook => {
  const { trackEvent: _trackEvent } = useTracking()
  const { getRuntimeConfigByKey } = useRuntimeConfig()

  const trackEvent = useCallback(
    (props: ITrackProps) => {
      const {
        eventName,
        content,
        customClientTrackingProperties,
        filter,
        search,
        sorting,
      } = props

      const products = mapExpertsToClientTrackingTrack(
        content,
        getRuntimeConfigByKey("MANDATOR_NUMBER"),
        {
          rating: true,
          tag: true,
        }
      )

      let filters = filter
        ? Object.keys(filter).map((key) => ({
            type: key,
            value: filter[key as FiltersModuleFieldId],
          }))
        : []

      if (search) {
        filters = [
          ...filters,
          {
            type: "query",
            value: search,
          },
        ]
      }

      _trackEvent({
        eventName,
        properties: {
          category: "Expert",
          currency: "EUR",
          products,
          ...(filters.length > 0 && { filters }),
          ...(sorting && {
            sorts: {
              type: sorting,
              value: DEFAULT_SORTING_MODE,
            },
          }),
          ...customClientTrackingProperties,
        },
      })
    },
    [_trackEvent, getRuntimeConfigByKey]
  )

  return { trackEvent }
}

export default useExpertsListingTracking
