import React from "react"
import { useTranslations } from "next-intl"

import { toPriceString } from "../../../utils/utils"
import { useRuntimeConfig } from "../../../hooks/runtimeConfig"
import Footnote from "../../Footnote"

interface IServiceFeeInfoProps {
  showServiceFee: boolean
  className?: string
}

const ServiceFeeInfo: React.FC<IServiceFeeInfoProps> = ({
  showServiceFee,
  className,
}) => {
  const translate = useTranslations()
  const { getRuntimeConfigByKey } = useRuntimeConfig()

  const serviceFeeEnabled = getRuntimeConfigByKey("SERVICE_FEE_IS_ENABLED")

  if (!serviceFeeEnabled || !showServiceFee) {
    return null
  }

  return (
    <Footnote
      className={className}
      notes={[
        { title: translate("footNotes.tax") },
        {
          title: translate("footNotes.serviceFee", {
            serviceFeeValue: toPriceString(
              getRuntimeConfigByKey("SERVICE_FEE_VALUE")
            ),
          }),
        },
      ]}
    />
  )
}

export default ServiceFeeInfo
