import React from "react"
import IconBase from "./IconBase"
import { IIconProps } from "./types"
import { useCheckBrand } from "./utils/useCheckBrand"

const GeminiIcon: React.FC<IIconProps> = ({ className }) => {
  const { isQuesticoBrand } = useCheckBrand()
  return (
    <IconBase className={className}>
      {isQuesticoBrand ? (
        <svg width="100%" height="100%" viewBox="0 0 35 38">
          <path
            fill="currentColor"
            d="M33.94 34.55c-2.702-1.503-5.59-2.643-8.59-3.39V6.46c2.966-.62 5.838-1.628 8.54-3L35 2.88 33.87.65l-1.11.57c-4.539 2.315-9.565 3.515-14.66 3.5C12.572 4.721 7.136 3.306 2.31.61L1.22 0 0 2.18l1.09.61c2.702 1.503 5.59 2.643 8.59 3.39v24.71c-2.97.62-5.844 1.629-8.55 3L0 34.47l1.13 2.22 1.12-.56c4.537-2.323 9.563-3.526 14.66-3.51 5.528-.001 10.964 1.414 15.79 4.11l1.09.61L35 35.16l-1.06-.61zm-21.76-4.1V6.7c1.955.338 3.936.512 5.92.52 1.589-.004 3.176-.114 4.75-.33v23.76c-1.954-.347-3.935-.524-5.92-.53-1.589.004-3.176.114-4.75.33z"
          />
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="100%"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M8.51 5.75c.93 3.92.83 7.89 0 11.9M16.06 5.75c-.93 3.92-.83 7.89 0 11.9M4 5c5.48 1.14 11.03 1.02 16.62 0"
          />
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M4 18.3c5.48-1.14 11.03-1.02 16.62 0"
          />
        </svg>
      )}
    </IconBase>
  )
}

export default GeminiIcon
