import React from "react"
import IconBase from "./IconBase"
import { IIconProps } from "./types"
import { useCheckBrand } from "./utils/useCheckBrand"

const TaurusIcon: React.FC<IIconProps> = ({ className }) => {
  const { isQuesticoBrand } = useCheckBrand()
  return (
    <IconBase className={className}>
      {isQuesticoBrand ? (
        <svg width="100%" height="100%" viewBox="0 0 38 35">
          <path
            fill="currentColor"
            d="M25.59 10.21l.77-1.49c1.954-3.827 5.893-6.23 10.19-6.22h1.25V0h-1.25c-5.234-.014-10.032 2.917-12.41 7.58l-.77 1.49c-.863 1.687-2.576 2.772-4.47 2.83-1.894-.06-3.605-1.144-4.47-2.83l-.77-1.49C11.282 2.917 6.484-.014 1.25 0H0v2.5h1.25c4.297-.01 8.236 2.393 10.19 6.22l.77 1.49c.546 1.058 1.33 1.975 2.29 2.68-4.891 2.193-7.572 7.519-6.418 12.754 1.153 5.235 5.823 8.941 11.183 8.875 5.36-.065 9.938-3.884 10.963-9.146 1.025-5.262-1.784-10.52-6.728-12.593.878-.692 1.592-1.57 2.09-2.57zm2.32 13c.004 3.572-2.145 6.795-5.444 8.165-3.3 1.37-7.099.617-9.626-1.908-2.528-2.524-3.285-6.323-1.919-9.624 1.366-3.3 4.587-5.453 8.159-5.453 4.878.006 8.83 3.962 8.83 8.84v-.02z"
          />
        </svg>
      ) : (
        <svg width="100%" height="100%" fill="none" viewBox="0 0 24 24">
          <path
            stroke="#38424F"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M6 4c4 4.17 8 4.17 12 0"
          />
          <path
            stroke="#38424F"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M12 19.12a6 6 0 1 0 0-12 6 6 0 0 0 0 12Z"
          />
        </svg>
      )}
    </IconBase>
  )
}

export default TaurusIcon
