import React from "react"
import clsx from "clsx"
import { Typography, Rating, StarIcon } from "../../_common"
import { IAdvisorRating } from "../../../types"
import classes from "./StarRating.module.scss"

interface IAverageRatingProps {
  rating: IAdvisorRating
  withDot?: boolean
  ratingCount?: number
  withCount?: boolean
  withRatingValue?: boolean
  className?: string
}

function getAvgRatingValue(
  ratingValue: number,
  withDot: boolean
): string | number {
  if (!withDot || ratingValue % 1 !== 0) return ratingValue

  return ratingValue.toFixed(1)
}

const StarRating: React.FC<IAverageRatingProps> = ({
  rating,
  withDot = true,
  withRatingValue = true,
  withCount = false,
  ratingCount = 0,
  className,
}) => {
  const { average_rounded: averageRounded, average } = rating
  const avgRatingValue = withRatingValue && getAvgRatingValue(average, withDot)

  return (
    <>
      <Rating
        value={averageRounded}
        precision={0.5}
        readOnly
        size="small"
        className={clsx(classes.ratingIcons, className)}
        emptyIcon={<StarIcon fontSize="inherit" />}
      />
      {avgRatingValue && (
        <Typography
          component="span"
          color="textPrimary"
          className={classes.ratingTextPrimary}
        >
          {avgRatingValue}
        </Typography>
      )}
      {withCount && (
        <Typography
          component="span"
          color="textPrimary"
          className={classes.ratingTextSecondary}
        >{`(${ratingCount})`}</Typography>
      )}
    </>
  )
}

export default StarRating
