import clsx from "clsx"
import React from "react"
import { Typography, Box } from "../_common"
import classes from "./Footnote.module.scss"

interface INote {
  title: string
  body?: string
}
interface IFootnoteProps {
  notes: Array<INote>
  className?: string
}

const Footnote: React.FC<IFootnoteProps> = ({ notes, className }) => {
  return (
    <Box className={clsx(classes.root, className)}>
      {notes.map((note, i) => (
        <Box key={i} className={classes.note}>
          <Typography className={classes.title}>{note.title}</Typography>
          {note.body && (
            <Typography className={classes.body}>{note.body}</Typography>
          )}
        </Box>
      ))}
    </Box>
  )
}

export default Footnote
