import React from "react"

import { IIconProps } from "./types"

import AquariusIcon from "./AquariusIcon"
import AriesIcon from "./AriesIcon"
import CancerIcon from "./CancerIcon"
import CapricornIcon from "./CapricornIcon"
import GeminiIcon from "./GeminiIcon"
import LeoIcon from "./LeoIcon"
import LibraIcon from "./LibraIcon"
import PiscesIcon from "./PiscesIcon"
import SagittariusIcon from "./SagittariusIcon"
import ScorpioIcon from "./ScorpioIcon"
import TaurusIcon from "./TaurusIcon"
import VirgoIcon from "./VirgoIcon"
import { ZodiacSignAliasesEnum } from "../../../types"

interface IZodiacSignIcon extends IIconProps {
  variant: ZodiacSignAliasesEnum
}

const ZodiacSignIcon: React.FC<IZodiacSignIcon> = (props) => {
  const { variant } = props

  switch (variant) {
    case ZodiacSignAliasesEnum.aquarius:
      return <AquariusIcon {...props} />
    case ZodiacSignAliasesEnum.aries:
      return <AriesIcon {...props} />
    case ZodiacSignAliasesEnum.cancer:
      return <CancerIcon {...props} />
    case ZodiacSignAliasesEnum.capricorn:
      return <CapricornIcon {...props} />
    case ZodiacSignAliasesEnum.gemini:
      return <GeminiIcon {...props} />
    case ZodiacSignAliasesEnum.leo:
      return <LeoIcon {...props} />
    case ZodiacSignAliasesEnum.libra:
      return <LibraIcon {...props} />
    case ZodiacSignAliasesEnum.pisces:
      return <PiscesIcon {...props} />
    case ZodiacSignAliasesEnum.sagittarius:
      return <SagittariusIcon {...props} />
    case ZodiacSignAliasesEnum.scorpio:
      return <ScorpioIcon {...props} />
    case ZodiacSignAliasesEnum.taurus:
      return <TaurusIcon {...props} />
    case ZodiacSignAliasesEnum.virgo:
      return <VirgoIcon {...props} />
    default:
      return null
  }
}

export default ZodiacSignIcon
