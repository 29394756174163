import React from "react"
import { IIconProps } from "./types"

const PinterestIcon: React.FC<IIconProps> = ({
  height = "100%",
  width = "100%",
}) => (
  <svg width={width} height={height} viewBox="0 0 39 39">
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M19.362.633c10.215 0 18.525 8.601 18.525 19.173 0 10.57-8.31 19.171-18.525 19.171-10.215 0-18.526-8.6-18.526-19.171C.836 9.234 9.146.633 19.362.633zm0 1.57c-9.38 0-17.01 7.897-17.01 17.603 0 9.705 7.63 17.602 17.01 17.602 9.379 0 17.009-7.897 17.009-17.602 0-9.706-7.63-17.603-17.01-17.603zm4.67 8.15c2.41 1.543 3.49 3.834 3.25 6.766-.146 1.866-.643 3.617-1.79 5.115-1.036 1.352-2.38 2.151-4.041 2.297-1.356.127-2.526-.28-3.41-1.484-.276 1.091-.539 2.132-.797 3.173-.38 1.542-1.19 2.843-2.103 4.087l-.055.078c-.037.05-.082.089-.16.068-.135-.039-.098-.178-.117-.273-.238-1.752-.22-3.478.209-5.204.356-1.46.687-2.926 1.018-4.392l.295-1.295c.049-.19.006-.368-.056-.551-.399-1.321-.429-2.616.338-3.815.484-.768 1.305-1.136 2.036-.959.87.21 1.348 1.144 1.171 2.248-.196 1.218-.626 2.36-.945 3.54-.214.807-.196 1.536.374 2.114.05.05.105.102.166.153.755.621 1.588.583 2.422.177.644-.317 1.11-.831 1.466-1.453 1.006-1.777 1.336-3.713 1.06-5.725-.25-1.879-1.36-3.078-3.127-3.548-2.164-.577-4.194-.247-5.85 1.403-1.46 1.454-1.987 3.275-1.582 5.357.092.475.306.9.62 1.263.201.228.263.47.195.748a11.19 11.19 0 0 1-.018.076c-.086.273-.141.553-.227.825-.11.369-.276.451-.625.293-1.245-.565-1.89-1.638-2.16-2.945-.625-3.078.387-5.579 2.718-7.501 2.648-2.177 6.88-2.457 9.725-.635z"
    />
  </svg>
)

export default PinterestIcon
