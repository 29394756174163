import React from "react"
import { IIconProps } from "./types"

const YoutubeIcon: React.FC<IIconProps> = ({
  height = "100%",
  width = "100%",
}) => (
  <svg width={width} height={height} viewBox="0 0 39 39">
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M19.288.122c10.215 0 18.526 8.6 18.526 19.173 0 10.57-8.31 19.171-18.526 19.171-10.215 0-18.526-8.6-18.526-19.171C.762 8.723 9.073.122 19.288.122zm0 1.57C9.91 1.692 2.28 9.588 2.28 19.295c0 9.705 7.63 17.602 17.01 17.602 9.378 0 17.008-7.897 17.008-17.602 0-9.707-7.63-17.603-17.009-17.603zm.003 10.377c2.506-.006 5.017 0 7.502.287 1.052.12 1.92.932 2.166 2.036.35 1.57.345 3.286.345 4.9v.543c-.004 1.456-.038 2.967-.345 4.363-.245 1.098-1.12 1.916-2.166 2.035-2.485.287-5.002.287-7.508.287-2.5 0-5.017 0-7.502-.287-1.046-.12-1.92-.937-2.166-2.035-.345-1.57-.345-3.287-.345-4.906v-.541c.004-1.452.039-2.964.351-4.36.246-1.103 1.114-1.916 2.166-2.035 2.484-.287 5.001-.293 7.502-.287zm-2.59 3.585v6.86l6.373-3.417c-2.136-1.16-4.249-2.295-6.373-3.443z"
    />
  </svg>
)

export default YoutubeIcon
