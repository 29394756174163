import React from "react"
import IconBase from "./IconBase"
import { IIconProps } from "./types"
import { useCheckBrand } from "./utils/useCheckBrand"

const CapricornIcon: React.FC<IIconProps> = ({ className }) => {
  const { isQuesticoBrand } = useCheckBrand()
  return (
    <IconBase className={className}>
      {isQuesticoBrand ? (
        <svg width="100%" height="100%" viewBox="0 0 44 32">
          <path
            fill="currentColor"
            d="M43.92 18.29c.082-3.692-2.718-6.813-6.397-7.13-3.68-.318-6.972 2.278-7.523 5.93h-.16C27.898 13.275 26.505 9.205 25.7 5c-.607-2.98-3.27-5.09-6.31-5-2.145.09-4.156 1.067-5.55 2.7-.15.18-.26.37-.39.56-.13-.19-.25-.38-.4-.56C11.656 1.067 9.645.09 7.5 0 5.473-.094 3.493.626 2 2L0 3.83l1.68 1.85 2-1.83c1.01-.927 2.35-1.41 3.72-1.34 1.45.055 2.81.715 3.75 1.82.66.794 1.015 1.798 1 2.83v15.28h2.5V7.16c-.011-1.032.343-2.034 1-2.83.975-1.134 2.385-1.8 3.88-1.83 1.805-.038 3.375 1.228 3.72 3 .79 3.84 2.68 11 6.32 15.56-.411 1.691-1.251 3.248-2.44 4.52-2.17 2.28-5.51 3.44-9.9 3.44H16v2.5h1.25c5.1 0 9.05-1.42 11.71-4.21 1.147-1.215 2.035-2.65 2.61-4.22.758.644 1.612 1.167 2.53 1.55.893.402 1.86.61 2.84.61h1.5v-.16c3.19-.71 5.465-3.532 5.48-6.8zm-6.86 4.48c-.687-.015-1.365-.157-2-.42-1.581-.728-2.596-2.309-2.6-4.05-.01-2.47 1.974-4.487 4.445-4.518 2.47-.03 4.503 1.937 4.554 4.407.05 2.47-1.9 4.52-4.369 4.591l-.03-.01z"
          />
        </svg>
      ) : (
        <svg width="100%" height="100%" fill="none" viewBox="0 0 24 24">
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M6 13.27V7.95c0-1.41 1.14-2.56 2.56-2.56 1.41 0 2.56 1.14 2.56 2.56v4.16c0 1.27.63 2.5 1.76 3.08.57.29 1.28.46 2.17.31 2.85-.46 2.85-4.27.09-4.47-2.74 0-3.25 4.01-3.46 7.42-.21 3.41-4.61 3.11-4.55.79M6 7.95V3"
          />
        </svg>
      )}
    </IconBase>
  )
}

export default CapricornIcon
