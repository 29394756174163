import React from "react"

import { TSocialNetwork } from "../../../types"
import { IIconProps } from "./types"

import FacebookIcon from "./FacebookIcon"
import InstagramIcon from "./InstagramIcon"
import PinterestIcon from "./PinterestIcon"
import YoutubeIcon from "./YoutubeIcon"
import TiktokIcon from "./TiktokIcon"

interface ISocialNetworkIcon extends IIconProps {
  variant: TSocialNetwork
}

const SocialNetworkIcon: React.FC<ISocialNetworkIcon> = (props) => {
  const { variant } = props

  switch (variant) {
    case "facebook":
      return <FacebookIcon {...props} />
    case "instagram":
      return <InstagramIcon {...props} />
    case "pinterest":
      return <PinterestIcon {...props} />
    case "youtube":
      return <YoutubeIcon {...props} />
    case "tiktok":
      return <TiktokIcon {...props} />
    default:
      return null
  }
}

export default SocialNetworkIcon
