import React from "react"
import IconBase from "./IconBase"
import { IIconProps } from "./types"
import { useCheckBrand } from "./utils/useCheckBrand"

const CancerIcon: React.FC<IIconProps> = ({ className }) => {
  const { isQuesticoBrand } = useCheckBrand()
  return (
    <IconBase className={className}>
      {isQuesticoBrand ? (
        <svg width="100%" height="100%" viewBox="0 0 36 35">
          <path
            fill="currentColor"
            d="M9.45 30c3.152-.705 5.471-3.389 5.71-6.61.24-3.22-1.656-6.219-4.67-7.382-3.012-1.163-6.431-.217-8.418 2.33C.085 20.884-.001 24.43 1.86 27.07l-.08.08 1 1c4.344 4.348 10.238 6.792 16.385 6.792 6.147 0 12.041-2.444 16.385-6.792l-1.77-1.77C27.344 32.797 17.475 34.265 9.45 30zM7.84 18c2.653.011 4.798 2.164 4.8 4.817.001 2.653-2.14 4.808-4.794 4.823-2.652.015-4.818-2.117-4.846-4.77.003-1.281.514-2.508 1.422-3.412.907-.904 2.137-1.41 3.418-1.408V18zM2.65 9.55C9.085 3.12 18.962 1.64 27 5.9c-3.156.697-5.482 3.38-5.727 6.602-.245 3.222 1.65 6.225 4.663 7.391 3.014 1.167 6.436.222 8.424-2.326 1.989-2.548 2.074-6.097.21-8.737l.08-.08-1-1C29.306 3.402 23.412.958 17.265.958 11.118.958 5.224 3.402.88 7.75L0 8.67l1.77 1.77.88-.89zm25.9 8.31c-2.657 0-4.813-2.15-4.82-4.807-.007-2.657 2.137-4.818 4.794-4.833 2.656-.015 4.824 2.123 4.846 4.78.008 1.284-.496 2.52-1.4 3.431-.905.912-2.136 1.426-3.42 1.429z"
          />
        </svg>
      ) : (
        <svg width="100%" height="100%" fill="none" viewBox="0 0 24 24">
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M6.51 13.34a3.51 3.51 0 1 0 0-7.02 3.51 3.51 0 0 0 0 7.02Z"
          />
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M14.88 6.42c-4.2-.31-8.86-1.12-10.85.93M17.49 17.51a3.51 3.51 0 1 0 0-7.02 3.51 3.51 0 0 0 0 7.02Z"
          />
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M9.12 17.4c4.2.31 8.86 1.12 10.85-.93"
          />
        </svg>
      )}
    </IconBase>
  )
}

export default CancerIcon
