import React from "react"
import IconBase from "./IconBase"
import { IIconProps } from "./types"
import { useCheckBrand } from "./utils/useCheckBrand"

const AquariusIcon: React.FC<IIconProps> = ({ className }) => {
  const { isQuesticoBrand } = useCheckBrand()
  return (
    <IconBase className={className}>
      {isQuesticoBrand ? (
        <svg width="100%" height="100%" viewBox="0 0 41 20">
          <path
            fill="currentColor"
            d="M31.87 7c-2.49 0-3.76-1.49-4.78-2.68-1.02-1.19-1.6-1.8-2.87-1.8s-1.9.65-2.88 1.8S19.05 7 16.56 7s-3.75-1.51-4.77-2.7c-1.02-1.19-1.61-1.8-2.88-1.8C7.64 2.5 7 3.15 6 4.3 5 5.45 3.74 7 1.25 7H0V4.48h1.25c1.28 0 1.9-.65 2.88-1.8S6.42 0 8.91 0s3.76 1.48 4.78 2.68c1.02 1.2 1.6 1.8 2.87 1.8s1.9-.65 2.88-1.8S21.73 0 24.22 0 28 1.48 29 2.68c1 1.2 1.61 1.8 2.88 1.8 1.27 0 1.89-.65 2.88-1.8S37 0 39.52 0h1.25v2.5h-1.25c-1.27 0-1.89.65-2.87 1.8-.98 1.15-2.29 2.7-4.78 2.7zM31.87 19.36c-2.49 0-3.76-1.48-4.78-2.68-1.02-1.2-1.6-1.8-2.87-1.8s-1.9.65-2.88 1.8-2.29 2.68-4.78 2.68-3.75-1.48-4.77-2.67c-1.02-1.19-1.61-1.81-2.88-1.81-1.27 0-1.91.65-2.91 1.81-1 1.16-2.29 2.67-4.78 2.67H0v-2.5h1.25c1.28 0 1.9-.65 2.88-1.8s2.29-2.68 4.78-2.68 3.76 1.49 4.78 2.68c1.02 1.19 1.6 1.8 2.87 1.8s1.9-.65 2.88-1.8 2.29-2.68 4.78-2.68S28 13.87 29 15.06c1 1.19 1.61 1.8 2.88 1.8 1.27 0 1.89-.65 2.88-1.8s2.28-2.68 4.77-2.68h1.25v2.5h-1.26c-1.27 0-1.89.65-2.87 1.8-.98 1.15-2.29 2.68-4.78 2.68z"
          />
        </svg>
      ) : (
        <svg width="100%" height="100%" fill="none" viewBox="0 0 24 24">
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M3 7c2.25 0 2.25 2.84 4.5 2.84S9.75 7 12 7s2.25 2.84 4.5 2.84S18.75 7 21 7M3 14.36c2.25 0 2.25 2.84 4.5 2.84s2.25-2.84 4.5-2.84 2.25 2.84 4.5 2.84 2.25-2.84 4.5-2.84"
          />
        </svg>
      )}
    </IconBase>
  )
}

export default AquariusIcon
