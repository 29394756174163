import React from "react"

import { Box } from "../../_common"
import RatingBucket from "./RatingBucket"

import { IAdvisorProfile } from "../../../types"
import classes from "./RatingOverview.module.scss"

type IRatingOverviewProps = IAdvisorProfile["reviews"]

const RatingOverview: React.FC<IRatingOverviewProps> = ({
  totals = [],
  count,
}) => {
  const sortedBuckets = totals
    .slice()
    .sort((a, b) => b.value - a.value)
    .filter((a) => !!a.value)

  return (
    <Box className={classes.chart}>
      {sortedBuckets.map((b) => (
        <RatingBucket
          key={b.value}
          ratingCount={b.count}
          ratingValue={b.value}
          ratingAverage={Number(b.value)}
          totalCount={count}
        />
      ))}
    </Box>
  )
}

export default RatingOverview
