import React from "react"
import { Box, Skeleton } from "../../_common"
import classes from "./FavoriteExpertItemPlaceholder.module.scss"

const FavoriteExpertItemPlaceholder: React.FC = () => {
  return (
    <Box
      className={classes.wrapper}
      data-testid="favorite-expert-item-placeholder"
    >
      <Box className={classes.avatarWrapper}>
        <Skeleton className={classes.avatarSkeleton} variant="rectangular" />
      </Box>
      <Box className={classes.detailsCol}>
        <Skeleton variant="text" className={classes.text} />
        <Skeleton variant="text" className={classes.text} />
        <Skeleton variant="text" className={classes.text} />
      </Box>
    </Box>
  )
}

export default FavoriteExpertItemPlaceholder
