import React from "react"
import { IIconProps } from "./types"

const FacebookIcon: React.FC<IIconProps> = ({
  height = "100%",
  width = "100%",
}) => (
  <svg width={width} height={height} viewBox="0 0 39 39">
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M19.288.633c10.215 0 18.526 8.601 18.526 19.173 0 10.57-8.31 19.171-18.526 19.171-10.215 0-18.525-8.6-18.525-19.171C.763 9.234 9.073.633 19.288.633zm0 1.57C9.91 2.203 2.28 10.1 2.28 19.806c0 9.705 7.63 17.602 17.01 17.602 9.378 0 17.008-7.897 17.008-17.602 0-9.706-7.63-17.603-17.009-17.603zm1.482 7.506l3.003.011v3.462h-2.178c-.356 0-.861.184-.861.972v2.096h3.088l-.354 3.563h-2.735v10.089H16.68V19.813h-1.926V16.25h1.926v-2.308c0-1.65.759-4.232 4.09-4.232z"
    />
  </svg>
)

export default FacebookIcon
