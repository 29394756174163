import React, { ComponentProps } from "react"
import clsx from "clsx"
import ProductsList from "./ProductsList"
import ProductPrice from "../ProductPrice"
import { hasFreeProductPromotion } from "./utils"
import ServiceFeeInfo from "./ServiceFeeInfo"
import classes from "./Products.module.scss"
import { IPromotion, IPromotionAdvisor, PromotionType } from "../../../types"

interface IProductsProps
  extends Pick<
    ComponentProps<typeof ProductsList>,
    | "promotion"
    | "listingNo"
    | "withPrice"
    | "photo"
    | "displayName"
    | "rootClassName"
    | "initSrc"
    | "advisorStatus"
    | "stepToSkip"
    | "onProductClick"
    | "customProductsLabels"
  > {
  offeredProducts: ComponentProps<typeof ProductsList>["products"]
  testId?: string
  productPriceClassName?: string
  productsListClassName?: string
  showServiceFee?: boolean
}

function isMarketPlaceAndNotWelcomePromotion(
  promotion?: IPromotion | IPromotionAdvisor | null
): boolean {
  if (!promotion || promotion.type === PromotionType.WELCOME_FREE_MINUTES) {
    return false
  }

  return "advisor_id" in promotion
    ? promotion.advisor_id === null
    : promotion.advisor === null
}

const Products: React.FC<IProductsProps> = ({
  listingNo,
  promotion,
  offeredProducts,
  withPrice = true,
  photo,
  displayName,
  rootClassName: _rootClassName,
  initSrc,
  advisorStatus,
  testId,
  productPriceClassName,
  productsListClassName,
  stepToSkip,
  onProductClick,
  customProductsLabels,
  showServiceFee,
}) => {
  const rootClassName = clsx(classes.root, _rootClassName)

  const hasFreePromotions = hasFreeProductPromotion(promotion)
  const useDefaultPromotionText = isMarketPlaceAndNotWelcomePromotion(promotion)

  return (
    <div className={rootClassName} data-testid={testId}>
      {withPrice && (
        <ProductPrice
          promotion={promotion}
          products={offeredProducts}
          rootClassName={productPriceClassName}
          promotionVariation={hasFreePromotions ? "ribbon" : "coupon-dense"}
          useDefaultPromotionText={useDefaultPromotionText}
        />
      )}
      <ProductsList
        promotion={promotion}
        products={offeredProducts}
        listingNo={listingNo}
        photo={photo}
        displayName={displayName}
        initSrc={initSrc}
        advisorStatus={advisorStatus}
        stepToSkip={stepToSkip}
        onProductClick={onProductClick}
        customProductsLabels={customProductsLabels}
        rootClassName={productsListClassName}
      />
      <ServiceFeeInfo
        showServiceFee={Boolean(showServiceFee) && !hasFreePromotions}
      />
    </div>
  )
}

export default Products
