import React from "react"
import { IIconProps } from "./types"

const StarFilledIcon: React.FC<IIconProps> = ({
  color = "currentColor",
  height = "100%",
  width = "100%",
}) => (
  <svg width={width} height={height} viewBox="0 0 16 16">
    <path
      fill={color}
      d="M12.345 15.429c-.192 0-.384-.06-.548-.179l-3.853-2.8-3.73 2.792c-.324.242-.762.249-1.093.018-.33-.231-.475-.646-.359-1.033L4.13 9.671.374 6.86C.052 6.62-.08 6.2.048 5.82c.127-.382.482-.639.884-.639H5.59L7.18.625c.134-.383.49-.628.9-.625.406.009.756.276.873.664l1.356 4.516h4.759c.404 0 .761.26.886.645s-.011.804-.339 1.043L11.758 9.67l1.474 4.536c.125.386-.011.805-.34 1.043-.163.119-.355.179-.547.179z"
    />
  </svg>
)

export default StarFilledIcon
