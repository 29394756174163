import React from "react"
import IconBase from "./IconBase"
import { IIconProps } from "./types"
import { useCheckBrand } from "./utils/useCheckBrand"

const SagittariusIcon: React.FC<IIconProps> = ({ className }) => {
  const { isQuesticoBrand } = useCheckBrand()
  return (
    <IconBase className={className}>
      {isQuesticoBrand ? (
        <svg width="100%" height="100%" viewBox="0 0 33 33">
          <path
            fill="currentColor"
            d="M32.44 1.77L31.97 1.3 31.97 0.47 31.14 0.47 30.67 0 30.2 0.47 20.07 0.47 20.07 2.97 27.7 2.97 15.65 15.02 10.3 9.67 8.54 11.44 13.88 16.79 0 30.67 1.77 32.44 15.65 18.55 21.81 24.71 23.58 22.94 17.42 16.79 29.47 4.73 29.47 12.37 31.97 12.37 31.97 2.23z"
          />
        </svg>
      ) : (
        <svg width="100%" height="100%" fill="none" viewBox="0 0 24 24">
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M6 18.68 18.48 6.2M18.68 14.34l-.2-8.14M10.34 6l8.14.2M6.78 12.74l5.21 5.21"
          />
        </svg>
      )}
    </IconBase>
  )
}

export default SagittariusIcon
