import React from "react"
import IconBase from "./IconBase"
import { IIconProps } from "./types"
import { useCheckBrand } from "./utils/useCheckBrand"

const LibraIcon: React.FC<IIconProps> = ({ className }) => {
  const { isQuesticoBrand } = useCheckBrand()
  return (
    <IconBase className={className}>
      {isQuesticoBrand ? (
        <svg width="100%" height="100%" viewBox="0 0 43 23">
          <path
            fill="currentColor"
            d="M42.29 15.12H30.22v-3.53c0-5.02-4.07-9.09-9.09-9.09s-9.09 4.07-9.09 9.09v3.53H0v-2.5h9.54v-1C9.54 5.219 14.729.03 21.13.03s11.59 5.189 11.59 11.59v1h9.57v2.5zM0 19.54H42V22.04H0z"
          />
        </svg>
      ) : (
        <svg width="100%" height="100%" fill="none" viewBox="0 0 24 24">
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M7.68 11.6c0-2.53 2.07-4.6 4.6-4.6s4.6 2.07 4.6 4.6M20.56 11.6h-3.68M7.68 11.6H4M20.56 16.02H4"
          />
        </svg>
      )}
    </IconBase>
  )
}

export default LibraIcon
