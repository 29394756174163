import React from "react"
import IconBase from "./IconBase"
import { IIconProps } from "./types"
import { useCheckBrand } from "./utils/useCheckBrand"

const PiscesIcon: React.FC<IIconProps> = ({ className }) => {
  const { isQuesticoBrand } = useCheckBrand()
  return (
    <IconBase className={className}>
      {isQuesticoBrand ? (
        <svg width="100%" height="100%" viewBox="0 0 32 38">
          <path
            fill="currentColor"
            d="M31.49 17.57h-9.2c.285-5.591 2.57-10.894 6.44-14.94l.86-.9L27.79 0l-.87.9c-4.32 4.51-6.863 10.431-7.16 16.67h-8C11.46 11.326 8.905 5.404 4.57.9L3.71 0 1.9 1.73l.86.9c3.87 4.046 6.155 9.349 6.44 14.94H0v2.5h9.2C8.91 25.657 6.625 30.955 2.76 35l-.86.9 1.81 1.73.86-.91c4.324-4.508 6.868-10.43 7.16-16.67h8c.297 6.239 2.84 12.16 7.16 16.67l.87.91 1.8-1.73-.86-.9c-3.88-4.063-6.167-9.388-6.44-15h9.2l.03-2.43z"
          />
        </svg>
      ) : (
        <svg width="100%" height="100%" fill="none" viewBox="0 0 24 24">
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M8.02 5c2.1 5.3 1.88 9.99 0 14.25M16.16 5c-2.1 5.3-1.88 9.99 0 14.25M6 12.13h12.18"
          />
        </svg>
      )}
    </IconBase>
  )
}

export default PiscesIcon
