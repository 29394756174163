import React from "react"
import { IIconProps } from "./types"

const InstagramIcon: React.FC<IIconProps> = ({
  height = "100%",
  width = "100%",
}) => (
  <svg width={width} height={height} viewBox="0 0 39 39">
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M19.362.122c10.215 0 18.525 8.6 18.525 19.173 0 10.57-8.31 19.171-18.525 19.171-10.215 0-18.526-8.6-18.526-19.171C.836 8.723 9.146.122 19.362.122zm0 1.57c-9.38 0-17.01 7.896-17.01 17.603 0 9.705 7.63 17.602 17.01 17.602 9.379 0 17.009-7.897 17.009-17.602 0-9.707-7.63-17.603-17.01-17.603zm4.575 8.394c2.385 0 4.323 2.005 4.323 4.48v9.463c0 2.474-1.938 4.474-4.323 4.474h-9.15c-2.386 0-4.323-2-4.323-4.474v-9.463c0-2.475 1.937-4.48 4.323-4.48zm-4.575 4.677c-2.417 0-4.379 2.03-4.379 4.53 0 2.508 1.962 4.539 4.379 4.539 2.422 0 4.378-2.03 4.378-4.538 0-2.5-1.956-4.531-4.378-4.531zm5.347-2.196c-.675 0-1.214.558-1.214 1.257 0 .692.54 1.25 1.214 1.25.668 0 1.208-.558 1.208-1.25 0-.699-.54-1.257-1.208-1.257z"
    />
  </svg>
)

export default InstagramIcon
