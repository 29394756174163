import React from "react"
import IconBase from "./IconBase"
import { IIconProps } from "./types"
import { useCheckBrand } from "./utils/useCheckBrand"

const LeoIcon: React.FC<IIconProps> = ({ className }) => {
  const { isQuesticoBrand } = useCheckBrand()
  return (
    <IconBase className={className}>
      {isQuesticoBrand ? (
        <svg width="100%" height="100%" viewBox="0 0 30 37">
          <path
            fill="currentColor"
            d="M29.54 27.43l-.4-1.18-2.37.81.41 1.18c.573 1.68.1 3.541-1.207 4.743-1.306 1.203-3.2 1.52-4.827.809-1.627-.711-2.68-2.316-2.686-4.092-.005-.75.181-1.49.54-2.15l.5-.74.75-.92-.08-.06L24.81 19l.7-1c.09-.12.16-.26.24-.39l.76-1.11-.11-.07c2.315-4.94.855-10.825-3.5-14.11-4.354-3.285-10.414-3.072-14.528.51C4.26 6.412 3.215 12.385 5.87 17.15h-.05c-3.344.266-5.887 3.118-5.769 6.47.118 3.353 2.854 6.02 6.209 6.05h.45c3.452-.25 6.05-3.247 5.81-6.7-.087-1.223-.536-2.393-1.29-3.36l.14-.18-1-.77c-.08-.071-.168-.135-.26-.19l-.1-.07C6.398 15.255 5.88 9.832 8.835 6.062c2.953-3.77 8.342-4.567 12.26-1.813 3.919 2.755 4.993 8.096 2.445 12.151l-6.68 9.86-.66 1 .17.11c-.993 2.64-.306 5.617 1.743 7.554 2.05 1.936 5.062 2.454 7.64 1.313 2.58-1.14 4.222-3.718 4.167-6.537.005-.773-.124-1.54-.38-2.27zM9.11 25.84c-1 1.146-2.59 1.578-4.033 1.098-1.442-.481-2.454-1.782-2.567-3.298-.126-2.063 1.429-3.845 3.49-4h.27c1.475.003 2.812.866 3.423 2.208.611 1.343.383 2.918-.583 4.032v-.04z"
          />
        </svg>
      ) : (
        <svg width="100%" height="100%" fill="none" viewBox="0 0 24 24">
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M10.764 14.079a2.79 2.79 0 1 0-5.566-.397 2.79 2.79 0 0 0 5.566.397Z"
          />
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M10.776 13.874c.11-2.05-.7-3.86-.88-5.86-.17-1.95.78-3.75 2.88-3.99 1.69-.19 3.57.77 4.05 2.47.33 1.15 0 2.3-.32 3.41-.36 1.23-.77 2.44-1.08 3.69-.39 1.61-.79 3.71.02 5.24.69 1.31 2.1 2.14 3.57 2.14"
          />
        </svg>
      )}
    </IconBase>
  )
}

export default LeoIcon
