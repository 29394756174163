import React from "react"
import IconBase from "./IconBase"
import { IIconProps } from "./types"
import { useCheckBrand } from "./utils/useCheckBrand"

const ScorpioIcon: React.FC<IIconProps> = ({ className }) => {
  const { isQuesticoBrand } = useCheckBrand()
  return (
    <IconBase className={className}>
      {isQuesticoBrand ? (
        <svg width="100%" height="100%" viewBox="0 0 42 30">
          <path
            fill="currentColor"
            d="M41 25.4l.51-.52-.51-.51v-.74h-.74L36.62 20l-1.76 1.77 1.9 1.9h-2c-2.101-.044-3.777-1.768-3.76-3.87V7c.014-2.583-1.625-4.885-4.07-5.715-2.447-.83-5.149-.003-6.71 2.055-1.137-1.464-2.886-2.321-4.74-2.321s-3.603.857-4.74 2.321c-1.57-2.023-4.251-2.827-6.675-2C1.64 2.164.008 4.438 0 7v1.24h2.5V7c0-1.933 1.567-3.5 3.5-3.5S9.5 5.067 9.5 7v14.73H12V7c0-1.25.667-2.406 1.75-3.031s2.417-.625 3.5 0C18.333 4.594 19 5.749 19 7v17.54h2.5V7c0-1.933 1.567-3.5 3.5-3.5s3.5 1.567 3.5 3.5v12.8c.006 3.494 2.836 6.324 6.33 6.33h2L34.86 28l1.76 1.77 3.67-3.68H41v-.69z"
          />
        </svg>
      ) : (
        <svg width="100%" height="100%" fill="none" viewBox="0 0 24 24">
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M4 14.67V9.14c0-1.47 1.19-2.65 2.65-2.65 1.47 0 2.65 1.19 2.65 2.65v5.53M9.31 14.67V9.14c0-1.47 1.19-2.65 2.65-2.65 1.47 0 2.65 1.19 2.65 2.65v8.46c0 1.22.99 2.21 2.21 2.21h.39c1.22 0 2.21-.99 2.21-2.21"
          />
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M14.62 9.14s3.73-1.55 4.54 1.22-6.62 9.24-9.35 9.71M4 9.14V4"
          />
        </svg>
      )}
    </IconBase>
  )
}

export default ScorpioIcon
