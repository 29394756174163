import React from "react"
import clsx from "clsx"

import { Typography, useTheme } from "../../_common"
import StarFilledIcon from "../../_common/QuesticoIcon/StarFilledIcon"
import classes from "./SimpleRating.module.scss"

interface IAverageRatingProps {
  ratingValue: number
  rootClassName?: string
}

function getAvgRatingValue(ratingValue: number): number {
  if (ratingValue % 1 === 0) return ratingValue

  return +ratingValue.toFixed(0)
}

const SimpleRating: React.FC<IAverageRatingProps> = ({
  ratingValue,
  rootClassName,
}) => {
  const theme = useTheme()
  const avgRatingValue = getAvgRatingValue(ratingValue)

  return (
    <div className={clsx(classes.root, rootClassName)}>
      <Typography
        component="span"
        color="textPrimary"
        className={classes.rating}
      >
        {avgRatingValue}
      </Typography>
      <StarFilledIcon
        color={theme.palette.contextualColors.attention}
        width={17}
      />
    </div>
  )
}
export default SimpleRating
