import React from "react"
import { IIconProps } from "./types"

const TiktokIcon: React.FC<IIconProps> = ({
  height = "100%",
  width = "100%",
}) => (
  <svg width={width} height={height} viewBox="0 0 40 40">
    <path
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M20 38c9.941 0 18-8.059 18-18S29.941 2 20 2 2 10.059 2 20s8.059 18 18 18Z"
    />
    <path
      fill="currentColor"
      d="M29.121 15.182c-1.022-.215-2.394-.883-3.248-2.3-.448-.742-.754-1.688-.793-2.881-.252-.002-.506 0-.758 0-.94-.001-1.882.007-2.822-.001v.5c.003 1.111.003 2.227.001 3.344-.005 2.618-.017 5.247-.021 7.865a836.33 836.33 0 0 0-.001 3.284c0 .91-.352 1.793-1.004 2.376a2.817 2.817 0 0 1-1.88.726c-1.126 0-2.103-.678-2.598-1.672a3.302 3.302 0 0 1-.344-1.473c0-1.738 1.318-3.146 2.942-3.146.296 0 .584.048.854.136v-3.981a6.242 6.242 0 0 0-.854-.06l-.022.001c-3.634.012-6.574 3.164-6.574 7.05 0 .09.003.178.006.266-.002.078-.006.156-.006.235 0 .102.003.203.006.304.11 2.729 1.867 5.082 4.313 5.868.843.271 1.659.377 2.276.377 3.372 0 6.15-2.706 6.545-6.197.02-.164.025-.328.026-.493.024-2.514.06-5.174.083-7.678 2.363 1.377 3.89 1.402 4.749 1.352.008-1.241-.008-2.484 0-3.725a3.17 3.17 0 0 1-.876-.075v-.002Z"
    />
  </svg>
)

export default TiktokIcon
