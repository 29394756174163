import React from "react"
import IconBase from "./IconBase"
import { IIconProps } from "./types"
import { useCheckBrand } from "./utils/useCheckBrand"

const VirgoIcon: React.FC<IIconProps> = ({ className }) => {
  const { isQuesticoBrand } = useCheckBrand()
  return (
    <IconBase className={className}>
      {isQuesticoBrand ? (
        <svg width="100%" height="100%" viewBox="0 0 44 34">
          <path
            fill="currentColor"
            d="M36.11 27.23c1.385-1.232 2.632-2.61 3.72-4.11 1.58-2.17 5.05-7.74 3.4-12.58-.98-2.89-3.85-4.7-6.88-4.34-1.273.155-2.477.66-3.48 1.46V6.31c.004-2.723-1.74-5.14-4.327-5.993-2.586-.853-5.427.052-7.043 2.243C20.315.95 18.434 0 16.435 0c-2 0-3.88.95-5.065 2.56C9.754.369 6.913-.536 4.327.317 1.74 1.17-.004 3.587 0 6.31v1.25h2.5V6.31c0-2.104 1.706-3.81 3.81-3.81s3.81 1.706 3.81 3.81v20.35h2.5V6.31c.104-2.031 1.781-3.625 3.815-3.625S20.146 4.28 20.25 6.31v20.35h2.5V6.31c0-2.104 1.706-3.81 3.81-3.81s3.81 1.706 3.81 3.81v14.54c-.01 2.22.691 4.386 2 6.18-.994.736-2.036 1.404-3.12 2-.97.526-1.972.99-3 1.39-.68.26-1.38.49-2.08.69l-1.17.33.68 2.41 1.21-.34c.78-.22 1.56-.48 2.3-.77 1.12-.436 2.209-.947 3.26-1.53 1.27-.69 2.488-1.476 3.64-2.35 1.867 1.564 4.225 2.421 6.66 2.42H42v-2.5h-1.21c-1.686-.002-3.326-.545-4.68-1.55zm-3.24-6.38v-8.14c.086-2.097 1.71-3.806 3.8-4 1.853-.214 3.601.9 4.19 2.67 1.32 3.83-2 8.86-3 10.31-1.013 1.385-2.173 2.656-3.46 3.79-.99-1.341-1.525-2.963-1.53-4.63z"
          />
        </svg>
      ) : (
        <svg width="100%" height="100%" fill="none" viewBox="0 0 24 24">
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M3 14.87v-4.59a2.2 2.2 0 0 1 4.4 0v4.59M7.41 14.87v-4.59a2.2 2.2 0 0 1 4.4 0v4.59c0 1.81 1.47 3.28 3.28 3.28 1.41 0 2.77-.56 3.77-1.56l2.01-2.01M3 10.27V6M21 18.6l-.1-4.04M16.86 14.46l4.04.1"
          />
        </svg>
      )}
    </IconBase>
  )
}

export default VirgoIcon
