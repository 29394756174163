import React from "react"

import { Box, Typography, Hidden } from "../../_common"
import SimpleRating from "./SimpleRating"
import StarRating from "./StarRating"

import { formatNumber } from "../../../utils/utils"
import classes from "./RatingBucket.module.scss"
import { TRatingValue } from "../../../types"

interface IRatingBucketProps {
  totalCount: number
  ratingCount: number | null
  ratingAverage: number
  ratingValue: TRatingValue
  __typename?: string
}

export function calcPercentage({
  ratingCount,
  totalCount,
}: Pick<IRatingBucketProps, "totalCount" | "ratingCount">): number {
  if (ratingCount === null || ratingCount === 0) return 0

  const percentage = (ratingCount / totalCount) * 100

  if (percentage === 0) return 0
  if (percentage < 1) return 1
  return Math.round(percentage)
}

const RatingBucket: React.FC<IRatingBucketProps> = ({
  ratingValue,
  ratingAverage,
  ratingCount = 0,
  totalCount = 0,
}) => {
  const percentage = calcPercentage({ ratingCount, totalCount })

  return (
    <Box className={classes.root}>
      <Hidden mdUp>
        <SimpleRating
          ratingValue={ratingValue}
          rootClassName={classes.simpleRating}
        />
      </Hidden>
      <Hidden mdDown>
        <StarRating
          withDot={false}
          rating={{
            average: ratingValue,
            average_rounded: ratingAverage,
            count: ratingValue,
          }}
        />
      </Hidden>
      <Box
        className={classes.bar}
        role="progressbar"
        aria-valuenow={percentage}
      >
        <Box
          className={classes.progressBar}
          style={{ width: `${percentage}%` }}
        />
      </Box>
      <Box className={classes.starRating}>
        <Typography
          className={classes.count}
          component="span"
          color="textSecondary"
        >
          {`(${formatNumber(ratingCount || 0)})`}
        </Typography>
      </Box>
    </Box>
  )
}

export default RatingBucket
