import React from "react"
import IconBase from "./IconBase"
import { IIconProps } from "./types"
import { useCheckBrand } from "./utils/useCheckBrand"

const AriesIcon: React.FC<IIconProps> = ({ className }) => {
  const { isQuesticoBrand } = useCheckBrand()
  return (
    <IconBase className={className}>
      {isQuesticoBrand ? (
        <svg width="100%" height="100%" viewBox="0 0 41 35">
          <path
            fill="currentColor"
            d="M36.93 3.11c-4.15-4.124-10.85-4.124-15 0-.763.737-1.413 1.583-1.93 2.51C18.411 2.655 15.513.616 12.186.121 8.858-.373 5.493.735 3.11 3.11c-4.152 4.158-4.152 10.892 0 15.05L4 19l1.77-1.77-.88-.88c-2.81-3.23-2.638-8.083.393-11.106 3.03-3.023 7.885-3.182 11.107-.364 1.53 1.521 2.387 3.592 2.38 5.75V34.2h2.5V10.63c.004-3.682 2.48-6.904 6.037-7.855 3.557-.952 7.31.603 9.152 3.792 1.842 3.19 1.313 7.217-1.289 9.823l-.89.88L36.05 19l.88-.88c4.13-4.147 4.13-10.853 0-15v-.01z"
          />
        </svg>
      ) : (
        <svg width="100%" height="100%" fill="none" viewBox="0 0 24 24">
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M4.38 12.2A4.114 4.114 0 0 1 3 9.11c0-1.06.41-2.14 1.25-2.94A4.09 4.09 0 0 1 7.12 5c.97 0 1.95.35 2.72 1.03 2.33 2.06 2.09 6.59 2.09 9.69l.06 3.4"
          />
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="m11.99 19.12.08-3.4c0-3.1-.24-7.63 2.09-9.69A4.16 4.16 0 0 1 16.88 5c1.03 0 2.07.4 2.87 1.17.84.81 1.25 1.88 1.25 2.94 0 1.14-.46 2.26-1.38 3.09"
          />
        </svg>
      )}
    </IconBase>
  )
}

export default AriesIcon
